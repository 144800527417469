import { combineReducers } from 'redux';
import assetsInfoReducer from './assetsInfoReducer';
import userInfoReducer from './userInfoReducer';
import utilityInfoReducer from './utilityInfoReducer';
import wishlistReducer from './wishlistReducer';

const rootReducer = combineReducers({
  assetsInfo: assetsInfoReducer,
  utilityInfo: utilityInfoReducer,
  userInfo: userInfoReducer,
  wishlistInfo: wishlistReducer
});

export default rootReducer;
