import { func, string, bool } from 'prop-types';
import React from 'react';
import AppLoader from '../AppLoader';

const AppButton = ({
  name,
  icon,
  classes,
  handleClick,
  iconBefore,
  iconAfter,
  isOutlined,
  onlyIcon,
  loading,
  ...rest
}) => {
  let buttonColorType = '';
  let isIconType = '';
  if (!onlyIcon) {
    buttonColorType = !isOutlined ? 'btn-primary' : 'btn-outline-primary';
    isIconType = 'btn';
  }
  return (
    <button
      type="button"
      className={`${isIconType} ${buttonColorType} ${classes}`}
      onClick={handleClick}
      {...rest}
    >
      {loading && <AppLoader />}
      {icon && iconBefore && (
        <img className="me-1 img-holder" src={icon} alt={name} />
      )}
      {name}
      {icon && iconAfter && <img className="me-1" src={icon} alt={name} />}
    </button>
  );
};

AppButton.defaultProps = {
  name: '',
  icon: '',
  classes: '',
  handleClick: () => {},
  iconBefore: false,
  iconAfter: false,
  isOutlined: false,
  loading: false,
  onlyIcon: false
};
AppButton.propTypes = {
  name: string,
  icon: string,
  classes: string,
  handleClick: func,
  iconBefore: bool,
  iconAfter: bool,
  isOutlined: bool,
  loading: bool,
  onlyIcon: bool
};

export default AppButton;
