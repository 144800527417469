import * as types from './types';

const initialState = {
  isOpen: false,
  isCardView: true,
  assetType: 1,
  openCreateAsset: false,
  openGenAsset: false,
  loading: false,
  openAddWishList: {
    data: null,
    status: false
  },
  filterShow: false,
  filterLoad: false,
  showFilteredData: false
};

const utilityInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDE_BAR:
      return {
        ...state,
        isOpen: action.payload
      };
    case types.SHOW_LIST_VIEW:
      return {
        ...state,
        isCardView: action.payload
      };
    case types.GET_ASSET_TYPE:
      return {
        ...state,
        assetType: action.payload
      };
    case types.OPEN_CREATE_ASSET:
      return {
        ...state,
        openCreateAsset: action.payload
      };
    case types.OPEN_GENERATE_ASSET:
      return {
        ...state,
        openGenAsset: action.payload
      };
    case types.SET_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case types.OPEN_ADD_WISHLIST:
      return {
        ...state,
        openAddWishList: {
          status: action.payload.status,
          data: action.payload.data || null
        }
      };
    case types.FILTER_SHOW:
      return {
        ...state,
        filterShow: action.payload
      };
    case types.FILTER_LOAD:
      return {
        ...state,
        filterLoad: action.payload
      };
    case types.SHOW_FILTER_DATA:
      return {
        ...state,
        showFilteredData: action.payload
      };
    case types.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default utilityInfoReducer;
