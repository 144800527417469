import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { string, func, array, bool } from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import AppButton from '../AppButtons/AppButton';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const AppInputGroup = (props) => {
  const {
    label,
    id,
    placeholder,
    button,
    handleClick,
    classes,
    options,
    hasText
  } = props;

  const {
    utilityInfo: { openCreateAsset }
  } = useSelector((state) => state);
  const [field, meta] = useField(props);
  const typeHeadRef = useRef();
  const [selectedValue, setselectedValue] = useState('');
  const handleChange = (value) => {
    // setselectedValue(value);
    handleClick(value);
    // setselectedValue(``);
  };
  const handleSelectedValue = () => {
    if (selectedValue) {
      handleClick(selectedValue);
      setselectedValue(``);
    }
  };
  useEffect(() => {
    if (!openCreateAsset) {
      setselectedValue('');
      if (typeHeadRef) typeHeadRef?.current?.clear();
    }
  }, [openCreateAsset]);
  return (
    <div className={`custom-from-group tags-input ${classes}`}>
      <label className="form-label">{label}</label>
      <div className="input-group mb-3">
        {!hasText ? (
          <Typeahead
            id={id}
            maxResults={false}
            options={options}
            paginate={false}
            placeholder={placeholder}
            aria-label={placeholder}
            aria-describedby={id}
            {...field}
            onChange={handleChange}
            // onChange={(e) => {
            //   console.log(`selectedValuee`, e);
            //   setselectedValue(e);
            // }}
            {...props}
            // value={selectedValue}
            ref={typeHeadRef}
            key={Math.random()}
          />
        ) : (
          <input
            className="form-control"
            placeholder={placeholder}
            aria-label={placeholder}
            aria-describedby={id}
            {...props}
            onChange={(e) => setselectedValue(e.target.value)}
            value={selectedValue}
          />
        )}
        <AppButton name={button} id={id} handleClick={handleSelectedValue} />
      </div>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
AppInputGroup.defaultProps = {
  id: 'id',
  placeholder: 'placeholder',
  type: 'text',
  label: 'title',
  button: 'Button',
  classes: '',
  hasText: false,
  reset: false,
  handleClick: () => {}
};
AppInputGroup.propTypes = {
  id: string,
  placeholder: string,
  type: string,
  label: string,
  button: string,
  handleClick: func,
  classes: string,
  hasText: bool,
  reset: bool,
  options: array.isRequired
};

export default AppInputGroup;
