/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import { array, bool, func, string } from 'prop-types';
import tagIcon from '../../assets/img/ic_tag.svg';
import closeIcon from '../../assets/img/ic_close.svg';
import AppButton from '../AppButtons/AppButton';

const AppTags = ({
  tags,
  closable,
  handleClick,
  classes,
  handleFilter,
  clearFilter,
  hasClear,
  filteredTags,
  iconClass,
  noTags
}) => {
  if (tags?.length === 0 && !noTags) return null;
  return (
    <div className="tags">
      {!noTags ||
        (filteredTags?.length > 0 && (
          <img className="mr-10" src={tagIcon} alt="Tag Icon" />
        ))}
      {hasClear && (
        <>
          <span className={`mr-10 ${iconClass}`} onClick={clearFilter}>
            <i className="cus-icons ic-close" />
          </span>
          {filteredTags && filteredTags.length > 0 && (
            <div
              className={`tag-list d-inline-flex ${
                hasClear && 'tag_filter_search'
              }`}
            >
              {filteredTags?.map((tag, i) => (
                <span
                  className="tag highlight"
                  key={i}
                  onClick={() => handleFilter(i, true)}
                >
                  {tag}
                  {closable && (
                    <AppButton
                      icon={closeIcon}
                      onlyIcon
                      classes="unset-all tag-close"
                      handleClick={() => handleClick(i, tag)}
                      iconAfter
                    />
                  )}
                </span>
              ))}
            </div>
          )}
        </>
      )}
      {!noTags && (
        <div className={`tag-list d-inline-flex ${classes}`}>
          {tags?.map((tag, i) => (
            <span className="tag" key={i} onClick={() => handleFilter(i)}>
              {tag}
              {closable && (
                <AppButton
                  icon={closeIcon}
                  onlyIcon
                  classes="unset-all tag-close"
                  handleClick={() => handleClick(i, tag)}
                  iconAfter
                />
              )}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

AppTags.defaultProps = {
  closable: false,
  handleClick: () => {},
  handleFilter: () => {},
  classes: '',
  iconClass: '',
  noTags: false
};
AppTags.propTypes = {
  tags: array.isRequired,
  closable: bool,
  noTags: bool,
  handleClick: func,
  handleFilter: func,
  classes: string,
  iconClass: string
};

export default AppTags;
