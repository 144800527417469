/* eslint-disable object-curly-newline */
import React, { useEffect, useRef, useState } from 'react';
import { number, object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../../components/AppButtons/IconButton';
import AppButton from '../../../components/AppButtons/AppButton';
import useOutsideClick from '../../../components/AppHooks/useOutsideClick';
import {
  addWishList,
  updateFolders,
  updateWishListData,
  getWishListData,
  formatFolder,
  updateWishList
} from '../../../redux/actions/wishlistActions';
import AppLoader from '../../../components/AppLoader';

const AddWishList = ({ id, type, data }) => {
  const dispatch = useDispatch();
  const wishListRef = useRef();
  const [openWishList, setOpenWishList] = useState(false);
  const [folderContent, setFolderContent] = useState([]);
  const [newFolder, setNewFolder] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchFolder, setSearchFolder] = useState('');
  const {
    userInfo: { user }
  } = useSelector((state) => state);
  const {
    wishlistInfo: { folders },
    utilityInfo: { assetType }
  } = useSelector((state) => state);
  const getWishList = async () => {
    const wishList = await getWishListData(user?._id);
    if (wishList.data.length > 0) {
      const wishListData = wishList.data;
      const getFolders = await formatFolder(wishListData, id);
      dispatch(updateFolders(getFolders));
      dispatch(updateWishList(wishListData));
    }
  };
  const handleWishList = () => {
    getWishList();
    setOpenWishList(!openWishList);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchFolder(searchValue);
    if (searchValue === '') {
      setFolderContent(folders);
    } else {
      const folderFilter = folders.filter((val) => {
        const lowerOriginal = val.name.toLowerCase();
        const lowerSearch = searchValue.toLowerCase();
        return lowerOriginal.indexOf(lowerSearch) >= 0;
      });
      setFolderContent(folderFilter);
    }
  };
  useEffect(() => {
    setFolderContent(folders);
  }, [folders]);
  const handleSelect = async (folderIndex) => {
    const updateRequest = folderContent[folderIndex];
    let requestBody = {
      list: [id],
      _id: updateRequest.id,
      active: updateRequest.active,
      index: folderIndex,
      type
    };
    if (assetType === 4) requestBody = { ...requestBody, lgData: data };
    await dispatch(updateWishListData(requestBody));
  };
  const handleNewFile = (e) => {
    setError('');
    setNewFolder(e.target.value.trim());
  };
  const handleAddFolder = async () => {
    if (newFolder) {
      setError('');
      setLoading(true);
      let wishListBody = {
        name: newFolder,
        list: [id],
        type
      };
      if (assetType === 4) wishListBody = { ...wishListBody, lgData: data };
      const wishListResponse = await dispatch(addWishList(wishListBody));
      if (wishListResponse) {
        dispatch(
          updateFolders(
            [{ name: newFolder, active: true, id: wishListResponse._id }],
            wishListResponse._id,
            true
          )
        );
      }
      setLoading(false);
    } else {
      setError('Folder name is required');
    }
  };

  useOutsideClick(wishListRef, () => setOpenWishList(false));
  return (
    <div className="link-btn-dropdown" ref={wishListRef}>
      <IconButton
        name="My Wishlist"
        icon="my-wishlist-icon"
        handleClick={handleWishList}
      />
      <div className={`link-dropdown-menu ${openWishList && 'show'}`}>
        <div className="mb-20 mt-2 custom-from-group tags-input ">
          <label htmlFor="asset-title" className="form-label">
            Create Newfolder
          </label>
          <div className="input-group mb-3">
            <div className="rbt">
              <input
                type="text"
                className="form-control"
                id="asset-title"
                placeholder="Name"
                name="assetTitle"
                value={newFolder}
                onChange={handleNewFile}
              />
              {error && <div className="error">{error}</div>}
            </div>
            <AppButton
              name="ADD"
              handleClick={handleAddFolder}
              id="customTags"
              loading={loading}
            />
          </div>
        </div>

        <div className="mb-10 search-input">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search my wishlist collection"
            value={searchFolder}
            onChange={handleSearch}
          />
        </div>
        <div className="wishlist">
          <ul>
            {folderContent &&
              folderContent.map((folder, i) => (
                <li
                  className={`${folder.active ? 'active' : ''} ${
                    folder?.loading ? 'loading' : ''
                  }`}
                  key={i}
                  onClick={() => handleSelect(i)}
                >
                  <i className="cus-icons my-wishlist-icon" />
                  <span>
                    {folder.name}
                    {folder?.loading ? <AppLoader /> : ''}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
AddWishList.defaultProps = {
  id: '',
  data: {}
};
AddWishList.propTypes = {
  id: string,
  type: number.isRequired,
  data: object
};

export default AddWishList;
