import React from 'react';
import PropTypes from 'prop-types';

const AppDropdown = ({ name, label, options, onChange, value, classes }) => {
  return (
    <div className={`dropdown-container ${classes}`}>
      <label htmlFor={name} className="dropdown-label">
        {label}
      </label>
      {options.length > 0 && (
        <select
          id={name}
          name={name}
          value={value || ''} // Pass the current selected value
          onChange={onChange} // Handle the onChange event
          className="form-select"
        >
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option) => (
            <option
              key={Number(option?.id) || option.value}
              value={Number(option?.id) || option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

AppDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func, // No longer required
  value: PropTypes.string.isRequired,
  classes: PropTypes.string
};

AppDropdown.defaultProps = {
  classes: '',
  onChange: () => {} // Default to an empty function if onChange is not provided
};

export default AppDropdown;
