/* eslint-disable no-param-reassign */
import * as types from './types';

const initialState = {
  folders: null,
  wishlist: [],
  filterList: null
};

const WishListInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_WISHLIST_FOLDERS:
      return {
        ...state,
        folders: [...action.payload]
      };
    case types.ADD_WISHLIST:
      return {
        ...state,
        wishlist: [...action.payload]
      };
    case types.UPDATE_WISHLIST_FOLDER:
      return {
        ...state,
        folders: action.payload
      };
    case types.FILTER_WISHLIST:
      return {
        ...state,
        filterList: action.payload
      };
    case types.UPDATE_WISHLIST:
      if (action.payload.list)
        state.wishlist[action.payload.index].list = action.payload.list;
      if (action.payload.notes)
        state.wishlist[action.payload.index].notes = action.payload.notes;
      if (action.payload.name)
        state.wishlist[action.payload.index].name = action.payload.name;
      if (action.payload?.desc)
        state.wishlist[action.payload.index].desc = action?.payload?.desc || {};
      return {
        ...state,
        wishlist: [...state.wishlist]
      };
    case types.RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default WishListInfoReducer;
