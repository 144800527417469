import { func, string } from 'prop-types';
import React from 'react';
import AppCopyLabel from '../AppLabelwithCopy';

const IconButton = ({ name, icon, handleClick, hasId, className }) => {
  return (
    <button
      type="button"
      className={`btn btn-outline-primary mr-20 ${className}`}
      onClick={handleClick}
    >
      {hasId ? (
        <span>
          ID
          <span className="ms-1 fw-400">
            <AppCopyLabel value={hasId} truncate noCopy />
          </span>
        </span>
      ) : (
        <span>{name}</span>
      )}

      <i className={`cus-icons ${icon}`} />
    </button>
  );
};

IconButton.defaultProps = {
  name: '',
  icon: '',
  hasId: '',
  className: '',
  handleClick: () => {}
};
IconButton.propTypes = {
  name: string,
  icon: string,
  hasId: string,
  className: string,
  handleClick: func
};

export default IconButton;
