import './App.css';
import './styles/common/common.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes, Route, useLocation } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import ErrorHandler from './components/AppErrorHandler';
import SignIn from './pages/SignIn';
import PrivateRoute from './containers/ProtectedRoute';
import AppLoader from './components/AppLoader';
// import AppLoader from './components/AppLoader';

const Assets = lazy(() => import('./pages/Assets'));
const WishList = lazy(() => import('./pages/WishList'));

const AssetsComponent = (props) => (
  <Suspense fallback={<AppLoader />}>
    <Assets {...props} />
  </Suspense>
);
const WishListComponent = (props) => (
  <Suspense fallback={<AppLoader />}>
    <WishList {...props} />
  </Suspense>
);

function App() {
  const location = useLocation();
  const { state } = location;

  return (
    <div className="App">
      <ToastContainer autoClose={2000} />
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <Routes location={state?.backgroundLocation || location}>
          <Route path="/signin" element={<SignIn />} />
          <Route element={<PrivateRoute />}>
            <Route path="/wishlist" element={<WishListComponent />}>
              <Route path=":id" element={<WishListComponent />} />
            </Route>
            <Route path="/" element={<AssetsComponent type={1} />} />
            <Route path="/videos" element={<AssetsComponent type={2} />}>
              <Route path=":id" element={<AssetsComponent type={2} />} />
            </Route>
            <Route path="/audios" element={<AssetsComponent type={3} />}>
              <Route path=":id" element={<AssetsComponent type={3} />} />
            </Route>
            <Route path="/images" element={<AssetsComponent type={1} />}>
              <Route path=":id" element={<AssetsComponent type={1} />} />
            </Route>
            <Route path="/gif" element={<AssetsComponent type={5} />}>
              <Route path=":id" element={<AssetsComponent type={5} />} />
            </Route>
            <Route
              path="/topicvideos"
              exact
              element={<AssetsComponent type={4} />}
            />
          </Route>
        </Routes>
        {/* <CreateAsset /> */}
      </ErrorBoundary>
    </div>
  );
}

export default App;
