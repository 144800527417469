import React from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../assets/img/ic_close.svg';

const AppDrawer = ({
  showDrawer,
  setShowDrawer,
  children,
  headertext,
  hasEdit,
  handleEdit
}) => {
  return (
    <div className="drawer-wrapper">
      <div
        className={`drawer offcanvas offcanvas-end ${showDrawer && 'show'}`}
        tabIndex="-1"
      >
        <div className={`offcanvas-header ${hasEdit && 'justify-left'}`}>
          {headertext && <h4 id="searchFilterLabel">{headertext}</h4>}
          {hasEdit && (
            <button
              type="button"
              className="unset-all text-reset edit-with-icon"
              aria-label="Close"
              onClick={handleEdit}
            >
              <i className="cus-icons ic-edit pointer edit-in-drawer" />{' '}
              <span className="fw-700 fs-1_1">Edit</span>
            </button>
          )}
          <button
            type="button"
            className={`unset-all text-reset ${hasEdit ? 'ml-2' : 'ms-auto'}`}
            aria-label="Close"
            onClick={setShowDrawer}
          >
            <img src={closeIcon} alt="Close Icon" />
          </button>
        </div>
        {children}
      </div>
      <div className={`drawer-fade ${showDrawer && 'show'}`} />
    </div>
  );
};

AppDrawer.defaultProps = {
  headertext: '',
  hasEdit: false,
  handleEdit: () => {}
};

AppDrawer.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  children: PropTypes.node.isRequired,
  headertext: PropTypes.string,
  hasEdit: PropTypes.bool
};
export default AppDrawer;
