import { shape, string } from 'prop-types';
import React from 'react';
// import Technology from '../assets/img/Technology.png';
import Technology from '../../../assets/img/Technology.png';

const ImageComponent = ({ path, name, info, view }) => {
  const handleOnError = (e) => {
    e.target.src = Technology;
  };
  const getThumbImage = () => {
    return path?.replace('/images/', '/thumbs/') || path;
  };
  return (
    <img
      src={view !== 'modal' ? getThumbImage() : path}
      onError={handleOnError}
      alt={name}
      style={{
        width: `${info?.dimention?.w}px`
      }}
      loading="lazy"
    />
  );
};

ImageComponent.propTypes = {
  path: string.isRequired,
  view: string.isRequired,
  name: string.isRequired,
  info: shape().isRequired
};

export default ImageComponent;
