/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './sidebar.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as utilityInfoAction from '../../redux/actions/utilityInfoAction';
import * as userAction from '../../redux/actions/userInfoAction';

import accountManagementIcon from '../../assets/img/ic_manage-accounts.svg';
import wishlistIcon from '../../assets/img/ic_wishlist.svg';
import createNewAssetIcon from '../../assets/img/ic_create-new-asset.svg';
import generateNewAssetIcon from '../../assets/img/ic_ai.svg';
import logoutIcon from '../../assets/img/ic_logout.svg';

function SideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const utilityInfo = useSelector((state) => state.utilityInfo);
  const userInfo = useSelector((state) => state.userInfo);
  const [menuItems] = useState([
    {
      id: 1,
      name: 'ACCOUNT MANAGEMENT',
      icon: accountManagementIcon,
      url: '/'
    },
    { id: 2, name: 'MY WISHLIST', icon: wishlistIcon, url: '/wishlist' },
    {
      id: 3,
      name: 'CREATE NEW ASSET',
      icon: createNewAssetIcon,
      event: 'create-asset'
    },
    {
      id: 4,
      name: 'GENERATE NEW IMAGE',
      icon: generateNewAssetIcon,
      event: 'generate-image'
    }
  ]);

  const selectMenu = (item) => {
    dispatch(utilityInfoAction.toggleSideBar(false));
    if (item.url) navigate(item.url);
    else if (item.event) {
      if (item.event === 'create-asset')
        dispatch(utilityInfoAction.openCreateAsset(true));
      if (item.event === 'generate-image')
        dispatch(utilityInfoAction.openGenerateAsset(true));
    }
  };
  const handleLogout = () => {
    dispatch(userAction.logout());
    navigate('/');
  };

  return (
    <div
      className={`ce-assets-mn-sidebar ${
        utilityInfo?.isOpen ? 'side-nav-open' : 'side-nav-close'
      } `}
    >
      <ul>
        {menuItems.length > 0 &&
          menuItems.map((item) => (
            <li key={item?.id} onClick={() => selectMenu(item)}>
              <img className="item-icon" src={item.icon} alt={item.name} />
              <span>{item?.name}</span>
            </li>
          ))}
      </ul>

      <ul className="mt-auto">
        <li className="logout" onClick={handleLogout}>
          <img className="item-icon" src={logoutIcon} alt="" />
          <span>
            {userInfo?.user?.firstname} {userInfo?.user?.lastname}
          </span>
        </li>
      </ul>
    </div>
  );
}

export default SideBar;
