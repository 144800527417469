/* eslint-disable object-curly-newline */
/* eslint-disable no-use-before-define */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import { func, shape, string, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
// import { useDispatch } from 'react-redux';
import * as assetAction from '../../redux/actions/assetsInfoAction';
import {
  findFileFormat,
  getBinaryFileData,
  getFileExtension,
  getFileMetaData,
  getFileName
} from '../../utils/helper';
import { assetTypeId } from '../../utils/constants';
import AppButton from '../AppButtons/AppButton';
import AppCopyLabel from '../AppLabelwithCopy';
import AppLoader from '../AppLoader';
// import { useField } from 'formik';
const CONST_PATH =
  'https://s3.amazonaws.com/thumbs.contentenablers.com/assetslib/images/sample.jpg';

const AppFileInput = (props) => {
  const {
    setFileDetail,
    validateForm,
    formRef,
    formEvents,
    setFieldValue,
    fileId,
    setFieldChange,
    reset,
    setCustomTags,
    customTags,
    ...rest
  } = props;

  const {
    utilityInfo: { openCreateAsset, openGenAsset },
    assetsInfo: { filters, midjourney }
  } = useSelector((state) => state);
  const [file, setFile] = useState(null);
  const [uploadName, setUploadName] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const assetValues = formRef?.current?.values;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(file);
    };
  }, []);
  useEffect(() => {
    if (!openCreateAsset) {
      setFileError(false);
      setFileInfo(null);
      setFile(null);
    }
  }, [openCreateAsset]);

  const uploadAsset = async () => {
    try {
      if (!fileInfo?.filePath) {
        setFileError(true);
      } else setFileError(false);
      const validate = await formRef.current.validateForm();
      await formRef.current.setTouched(validate);
      const validateEntries = Object.entries(validate);
      if (
        validateEntries.length <= 0 ||
        (validateEntries.length === 1 && validateEntries[0][0] === 'fileId')
      ) {
        setUploading(true);
        const adminTags = assetValues.adminTags.concat(
          assetValues.courseCategory
        );
        const assetRequest = {
          rtype: assetTypeId[fileInfo?.fileType],
          name: assetValues.assetTitle,
          path: CONST_PATH,
          cids: [...assetValues.courseContent],
          tags: {
            admin: [...new Set(adminTags)],
            custom: assetValues.customTags
          },
          rights: [assetValues.rights],
          info: {
            resolution: fileInfo?.duration || 0,
            dimention: {
              w: fileInfo?.width || 0,
              h: fileInfo?.height || 0
            },
            size: fileInfo?.size
          }
        };
        const createAssetRes = await dispatch(
          assetAction.createAsset(assetRequest)
        );
        if (createAssetRes) {
          setFieldValue('fileId', createAssetRes.data.id);
          const uploadPath = await handleUpload(createAssetRes.data.id);
          if (uploadPath) {
            const updateRequest = {
              ...assetRequest,
              id: createAssetRes.data.id,
              path: uploadPath
            };
            await dispatch(assetAction.updateAsset(updateRequest, false));
          }
          // await dispatch(
          //   assetAction.filterAssetList(assetTypeId[fileInfo?.fileType])
          // );
          dispatch(assetAction.advanceFilter(filters));
        }
        setFieldChange(false);
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);
    }
  };

  const handleUpload = async (id) => {
    try {
      if (!fileInfo?.filePath) {
        setFileError(true);
      } else setFileError(false);
      const validate = await formRef.current.validateForm();
      await formRef.current.setTouched(validate);
      const validateEntries = Object.entries(validate);
      if (
        validateEntries.length <= 0 ||
        (validateEntries.length === 1 && validateEntries[0][0] === 'fileId')
      ) {
        setUploading(true);
        const fileExtension = getFileExtension(fileInfo.name);
        const headers = {
          'upload-id': `${id}.${fileExtension}`,
          'Content-Type': fileInfo.type
        };
        const getBinaryData = await getBinaryFileData(fileInfo.file);
        const { data } = await assetAction.uploadAssetFile(
          headers,
          getBinaryData
        );
        if (data && data.data) {
          const fileData = {
            ...fileInfo,
            filePath: data.data.path
          };
          setFieldValue('filePath', data.data.path);
          setFileInfo(fileData);
          return data.data.path;
        }
      }
      return null;
    } catch (error) {
      setUploading(false);
      return null;
    }
  };
  // Create new image
  const simulateFileChange = async () => {
    try {
      // Simulate the behavior as if a user selected a file
      const blob = await fetch(midjourney?.proxy_url).then((response) =>
        response.blob()
      );
      const fakeFile = new File([blob], 'filename.png', { type: blob.type });
      const syntheticEvent = {
        target: {
          files: [fakeFile]
        }
      };
      // Call the handler function with the synthetic event
      await handleFileChange(syntheticEvent);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = async (e) => {
    try {
      setFieldValue('filePath--------', e);
      setFileError(false);
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setFile(objectUrl);
      setFieldValue('filePath', objectUrl);
      const findFileType = findFileFormat(e.target.files[0].type);
      const metaData = await getFileMetaData(e.target.files[0]);
      const fileName = getFileName(e.target.files[0].name);
      let metaArray = [fileName];
      if (metaData?.Keywords) metaArray = [...metaArray, ...metaData.Keywords];
      let fileData = {
        file: e.target.files[0],
        filePath: objectUrl,
        fileType: findFileType,
        size: e.target.files[0].size,
        name: e.target.files[0].name,
        type: e.target.files[0].type
      };
      const customTagValue = [...new Set([...customTags, ...metaArray])];
      const getFileIndex = customTagValue.findIndex(
        (tag) => tag === uploadName
      );
      if (getFileIndex >= 0) customTagValue.splice(getFileIndex, 1);
      setCustomTags(customTagValue);
      setFieldValue('customTags', customTagValue);
      setUploadName(fileName);
      if (findFileType === 'Image' || findFileType === 'Gif') {
        const img = new Image();
        img.onload = function (event) {
          fileData = {
            ...fileData,
            width: this.width,
            height: this.height
          };
          setFileInfo(fileData);
        };
        img.src = objectUrl;
      } else {
        const elementType = findFileType === 'Video' ? 'video' : 'audio';
        const video = document.createElement(elementType);
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
          fileData = {
            ...fileData,
            duration: video.duration,
            width: video.videoWidth,
            height: video.videoHeight
          };
          setFileInfo(fileData);
        };
        video.src = objectUrl;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (openGenAsset) simulateFileChange();
  }, [openCreateAsset]);
  useEffect(() => {
    if (fileInfo) setFileDetail({ ...fileInfo });
  }, [fileInfo]);
  useEffect(() => {
    if (reset) {
      setFileInfo(null);
      setFileDetail(null);
    }
  }, [reset]);

  const handleDeleteFile = () => {
    setFile(null);
    setFileInfo(null);
  };
  return (
    <>
      <div className="file-upload-input mb-3">
        {/* Name of file chosen */}
        <div className="input-group">
          <label className="file-name">
            {(fileInfo && fileInfo.name) || 'Browse'}
          </label>
          <input
            name="filePath"
            type="file"
            id="fileUpload"
            hidden={openGenAsset}
            onChange={handleFileChange}
            {...rest}
          />
          <AppButton
            name="Upload"
            classes="upload-btn"
            disabled={uploading || fileId}
            loading={uploading}
            handleClick={uploadAsset}
          />
        </div>
        {/* <label forHtml="fileUpload" className="file-name">
          {(fileInfo && fileInfo.name) || 'Browse'}
        </label>

        <div className="upload-btn">
          <label onClick={handleUpload}>Upload</label>
          <input
            name="filePath"
            type="file"
            id="fileUpload"
            // hidden
            onChange={handleFileChange}
            {...rest}
          />
        </div> */}
      </div>
      {fileError ? <div className="error">File is required</div> : null}
      <div className="uploaded-img mb-20">
        {file && (
          <>
            {(fileInfo?.fileType === 'Image' ||
              fileInfo?.fileType === 'Gif') && (
              <img src={fileInfo?.filePath} alt="asset file" />
            )}
            {fileInfo?.fileType === 'Video' && (
              <video height="100%" width="100%">
                <track kind="captions" />
                <source src={fileInfo?.filePath} />
              </video>
            )}
            {fileInfo?.fileType === 'Audio' && (
              <audio>
                <track kind="captions" />
                <source src={fileInfo?.filePath} />
              </audio>
            )}
            <button
              type="button"
              className="unset-all delete-btn"
              onClick={handleDeleteFile}
            >
              <i className="cus-icons delete-icon" />
            </button>
          </>
        )}
      </div>
      <div className="uploaded-img-info">
        <i className="cus-icons info-icon mr-10 mt-1" />
        <div className="mr-30">
          <label>ID No.</label>
          <span>{<AppCopyLabel value={fileId} truncate /> || ''}</span>
        </div>
        <div className="mr-30">
          <label>Asset Type</label>
          <span>{fileInfo?.fileType}</span>
        </div>
        <div>
          {fileInfo?.width && (
            <>
              <label>Dimensions</label>
              <span>{`${fileInfo?.width}px x ${fileInfo?.height}px`}</span>
            </>
          )}
          {fileInfo?.duration && (
            <>
              <label>Duration</label>
              <span>{`${fileInfo?.duration}seconds`}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};
AppFileInput.defaultProps = {
  fileId: '',
  reset: false,
  customTags: '',
  setFileDetail: () => {},
  validateForm: () => {},
  setFieldValue: () => {},
  setFieldChange: () => {},
  setCustomTags: () => {},
  formEvents: { touched: {}, errors: {} }
};
AppFileInput.propTypes = {
  setFileDetail: func,
  validateForm: func,
  setFieldValue: func,
  setFieldChange: func,
  setCustomTags: func,
  reset: bool,
  onUpload: func.isRequired,
  formRef: func.isRequired,
  fileId: string,
  customTags: string,
  formEvents: shape({
    touched: shape(),
    errors: shape()
  })
};

export default AppFileInput;
