export const assetTypeId = {
  Image: 1,
  Video: 2,
  Audio: 3,
  Gif: 5,
  Ai: 10
};
export const sampleAdminTags = ['admintags', 'tag2'];
export const sampleCustomTags = ['customtags', 'tag3'];
export const ASSET_TYPES = ['Image', 'Video', 'Audio'];
export const LEGACYPERMISSION = [
  'kara@contentenablers.com',
  'kim@contentenablers.com',
  'assetmgr@ceauthor.com',
  'admin@ceauthor.com'
];
export const CONFIG_PATH =
  'https://dg7zdxz92ywv.cloudfront.net/platform/configs/ceassets-config.json';
