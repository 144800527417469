export const ALL_ASSETS = 'ALL_ASSETS';
export const FILTERED_ASSETS = 'FILTERED_ASSETS';
export const ADVANCE_FILTER = 'ADVANCE_FILTER';
export const GET_ASSET = 'GET_ASSET';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_ADMIN_TAGS = 'UPDATE_ADMIN_TAGS';
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';
export const SHOW_LIST_VIEW = 'SHOW_LIST_VIEW';
export const GET_ASSET_TYPE = 'GET_ASSET_TYPE';
export const OPEN_CREATE_ASSET = 'OPEN_CREATE_ASSET';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const FETCH_CONTENT_SET = 'FETCH_CONTENT_SET';
export const SET_LOADER = 'SET_LOADER';
export const OPEN_ADD_WISHLIST = 'OPEN_ADD_WISHLIST';
export const ADD_WISHLIST_FOLDERS = 'ADD_WISHLIST_FOLDERS';
export const UPDATE_WISHLIST_FOLDER = 'UPDATE_WISHLIST_FOLDER';
export const ADD_WISHLIST = 'ADD_WISHLIST';
export const UPDATE_WISHLIST = 'UPDATE_WISHLIST';
export const FILTER_WISHLIST = 'FILTER_WISHLIST';
export const SET_USERS = 'SET_USERS';
export const RESET = 'RESET';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_FILTERS_TAGS = 'SET_FILTERS_TAGS';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE';

export const SET_ARCHIVE_COURSES = 'SET_ARCHIVE_COURSES';
export const SET_TV_SEARCH_CONTENT = 'SET_TV_SEARCH_CONTENT';
export const SELECTED_TV_COURSE = 'SELECTED_TV_COURSE';

export const OPEN_GENERATE_ASSET = 'OPEN_GENERATE_ASSET';
export const SET_MIDJOURNEY_DATA = 'SET_MIDJOURNEY_DATA';
export const RESET_MIDJOURNEY_DATA = 'RESET_MIDJOURNEY_DATA';

export const FETCH_CLIENT_LIST = 'FETCH_CLIENT_LIST';
export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const FETCH_JSON_DATA_REQUEST = 'FETCH_JSON_DATA_REQUEST';
export const FETCH_JSON_DATA_SUCCESS = 'FETCH_JSON_DATA_SUCCESS';
export const FETCH_JSON_DATA_FAILURE = 'FETCH_JSON_DATA_FAILURE';
export const LANGUAGE_DATA_LIST = 'LANGUAGE_DATA_LIST';
export const LANGUAGE_DATA_CREATE = 'LANGUAGE_DATA_CREATE';
export const LANGUAGE_FETCH_LOAD = 'LANGUAGE_FETCH_LOAD';
export const LANGUAGE_DATA_FETCH = 'LANGUAGE_DATA_FETCH';
export const LANGUAGE_DATA_UPDATE = 'LANGUAGE_DATA_UPDATE';
export const GET_ASSETS_BY_TYPE = 'GET_ASSETS_BY_TYPE';
export const FILTER_SHOW = 'FILTER_SHOW';
export const FILTER_LOAD = 'FILTER_LOAD';
export const DATA_LOADED = 'DATA_LOADED';
export const SHOW_FILTER_DATA = 'SHOW_FILTER_DATA';
