import * as types from '../reducers/types';

export const toggleSideBar = (payload) => {
  return {
    type: types.TOGGLE_SIDE_BAR,
    payload
  };
};
export const toggleGridView = (payload) => {
  return {
    type: types.SHOW_LIST_VIEW,
    payload
  };
};
export const updateAssetType = (payload) => {
  return {
    type: types.GET_ASSET_TYPE,
    payload
  };
};
export const openCreateAsset = (payload) => {
  return {
    type: types.OPEN_CREATE_ASSET,
    payload
  };
};
export const openGenerateAsset = (payload) => {
  return {
    type: types.OPEN_GENERATE_ASSET,
    payload
  };
};

export const openAddWishList = (payload) => {
  return {
    type: types.OPEN_ADD_WISHLIST,
    payload
  };
};
export const setloader = (payload) => {
  return {
    type: types.SET_LOADER,
    payload
  };
};
