/* eslint-disable no-unused-vars */
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as types from '../../redux/reducers/types';
// import { useSelector } from 'react-redux';
import Layout from '../Layout/Layout';
import cookies from '../../utils/cookies';
import { LEGACYPERMISSION } from '../../utils/constants';
import AssetLayout from '../Layout/AssetLayout';

// let assetRoutes = ['', 'images', 'videos', 'audios', 'topicVideos', 'gif'];
const PrivateRoute = () => {
  const user = cookies.getItem('asset_user');
  const dispatch = useDispatch();
  const location = useLocation();
  const locationpath = location.pathname.split('/')[1];
  if (!user) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  const parsedJson = JSON.parse(user);
  const assetRoutes = [
    '',
    'images',
    'videos',
    'audios',
    LEGACYPERMISSION.includes(parsedJson?.email) ? 'topicVideos' : '',
    'gif'
  ];
  dispatch({
    type: types.SET_USERS,
    payload: parsedJson
  });
  if (assetRoutes.includes(locationpath)) {
    return (
      <AssetLayout>
        <Outlet />
      </AssetLayout>
    );
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;
