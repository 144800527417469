/* eslint-disable eqeqeq */
import * as types from '../reducers/types';
import { messageError } from '../../components/AppToaster';
import apiRequests from '../../services/axiosService';
import { setloader } from './utilityInfoAction';
import cookies from '../../utils/cookies';

export function onLogin(body) {
  return async (dispatch) => {
    try {
      dispatch(setloader(true));
      const token = btoa(`${body.email}:${body.password}`);
      const userResponse = await apiRequests({
        method: 'post',
        url: 'clients/login',
        body,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (userResponse.data.status === 'failure') {
        messageError('Invalid email/password');
        return null;
      }
      const userData = userResponse.data.data[0];
      dispatch({
        type: types.SET_USERS,
        payload: userData
      });
      cookies.setItem('asset_user', JSON.stringify(userData));
      return userData;
    } catch (error) {
      return null;
    }
  };
}

export function logout() {
  return async (dispatch) => {
    try {
      cookies.removeItem('asset_user');
      dispatch({
        type: types.SET_USERS,
        payload: null
      });
      dispatch({
        type: types.RESET,
        payload: null
      });
      return null;
    } catch (error) {
      return null;
    }
  };
}
